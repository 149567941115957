body {
    margin: 0;
    padding: 0;
}

/* Header Styles */
.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
}


.header h1 {
    margin-left: auto;
    margin-right: auto;
    font-size: 45px;

    margin-top: 0;
    padding: 0;

}

.toggle {
    margin-left: 25px;
   
    height: 45px;
    background: inherit;
    border: 0;
    padding: 0; 
    margin-top: 0;
}

.toggle i {
    padding: 0; 
    margin-top: 0;

}


.toggle .fa-bars {
    font-size: 45px;
    color: black;

}


.toggle .fa-times {
    color: white;
    font-size: 60px;
    margin-top: 25px;
    -webkit-text-stroke: 4px rgb(96, 70, 70);

    position: relative;
    z-index: 2;
}



/* Navpage Styles */


.navpage {
    display: flex;
    flex-direction: column;
    text-align: center;

}

.nav-item {
    color: white;
    font-size: 40px;
    font-family: inherit;
    text-decoration: inherit;

}

.header-hidden {
    display: none;
    color: blue;
}


/* Content Styles */
.content {
    margin-left: 10vw;
    margin-right: 10vw;
}

.MathJax:not(h3):not(h2) {
    color: black;
}

/* Font Styles */
@font-face {
    font-family: baskerville;
    src: url(media/LibreBaskerville-Regular.ttf);
}

@font-face {
    font-family: hind-madurai;
    src: url(media/HindMadurai-Medium.ttf);
}

.content h1 {
    font-size: 45px;    
}

h2 {
    font-family:  hind-madurai; 
}

p {
    font-family: baskerville;
    font-size: 18px;
    line-height: 1.6;
}